import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AcroynaLogo from '../images/Acroyna_logo2.png';

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleLinkClick = () => {
    setMenuOpen(false);
  };

  return (
    <nav className="bg-blue-700 text-white px-4 py-3 flex flex-col md:flex-row md:justify-between items-center fixed top-0 left-0 w-full z-50 shadow-md">
      <div className="flex items-center justify-between w-full md:w-auto">
        <Link
          to="/"
          className="flex items-center gap-2 hover:text-blue-300"
          onClick={handleLinkClick}
        >
          <img
            src={AcroynaLogo}
            alt="Acroyna Logo"
            className="h-8 w-8 object-contain md:h-10 md:w-10"
          />
          <span className="text-xl font-bold leading-tight text-left">
            Acroyna Software Solutions
          </span>
        </Link>

        <button
          onClick={() => setMenuOpen(!menuOpen)}
          className="md:hidden focus:outline-none"
          aria-label="Toggle menu"
        >
          ☰
        </button>
      </div>

      <ul
        className={`mt-4 md:mt-0 md:flex transition-all duration-300 ease-in-out gap-2 md:gap-6 ${
          menuOpen ? 'flex flex-col items-center w-full' : 'hidden'
        } md:block`}
      >
        <li className="py-2 md:py-0">
          <Link to="/" className="hover:text-blue-300" onClick={handleLinkClick}>
            Home
          </Link>
        </li>
        <li className="py-2 md:py-0">
          <Link to="/about" className="hover:text-blue-300" onClick={handleLinkClick}>
            About
          </Link>
        </li>
        <li className="py-2 md:py-0">
          <Link to="/services" className="hover:text-blue-300" onClick={handleLinkClick}>
            Services
          </Link>
        </li>
        <li className="py-2 md:py-0">
          <Link to="/contact" className="hover:text-blue-300" onClick={handleLinkClick}>
            Contact Us
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
